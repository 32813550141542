:root, .light, .light-theme {
  --jade-a1: #00c08004;
  --jade-a2: #00a3460b;
  --jade-a3: #00ae4819;
  --jade-a4: #00a85129;
  --jade-a5: #00a2553c;
  --jade-a6: #009a5753;
  --jade-a7: #00945f74;
  --jade-a8: #00976ea9;
  --jade-a9: #00916bd6;
  --jade-a10: #008764d9;
  --jade-a11: #007152df;
  --jade-a12: #002217e2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --jade-a1: color(display-p3 0.024 0.757 0.514 / 0.016);
      --jade-a2: color(display-p3 0.024 0.612 0.22 / 0.04);
      --jade-a3: color(display-p3 0.012 0.596 0.235 / 0.087);
      --jade-a4: color(display-p3 0.008 0.588 0.255 / 0.142);
      --jade-a5: color(display-p3 0.004 0.561 0.251 / 0.204);
      --jade-a6: color(display-p3 0.004 0.525 0.278 / 0.287);
      --jade-a7: color(display-p3 0.004 0.506 0.29 / 0.397);
      --jade-a8: color(display-p3 0 0.506 0.337 / 0.561);
      --jade-a9: color(display-p3 0 0.459 0.298 / 0.683);
      --jade-a10: color(display-p3 0 0.42 0.271 / 0.702);
      --jade-a11: color(display-p3 0.15 0.5 0.37);
      --jade-a12: color(display-p3 0.142 0.229 0.194);
    }
  }
}