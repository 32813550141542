:root, .light, .light-theme {
  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --amber-1: color(display-p3 0.995 0.992 0.985);
      --amber-2: color(display-p3 0.994 0.986 0.921);
      --amber-3: color(display-p3 0.994 0.969 0.782);
      --amber-4: color(display-p3 0.989 0.937 0.65);
      --amber-5: color(display-p3 0.97 0.902 0.527);
      --amber-6: color(display-p3 0.936 0.844 0.506);
      --amber-7: color(display-p3 0.89 0.762 0.443);
      --amber-8: color(display-p3 0.85 0.65 0.3);
      --amber-9: color(display-p3 1 0.77 0.26);
      --amber-10: color(display-p3 0.959 0.741 0.274);
      --amber-11: color(display-p3 0.64 0.4 0);
      --amber-12: color(display-p3 0.294 0.208 0.145);
    }
  }
}