:root, .light, .light-theme {
  --green-a1: #00c04004;
  --green-a2: #00a32f0b;
  --green-a3: #00a43319;
  --green-a4: #00a83829;
  --green-a5: #019c393b;
  --green-a6: #00963c52;
  --green-a7: #00914071;
  --green-a8: #00924ba4;
  --green-a9: #008f4acf;
  --green-a10: #008647d4;
  --green-a11: #00713fde;
  --green-a12: #002616e6;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --green-a1: color(display-p3 0.024 0.757 0.267 / 0.016);
      --green-a2: color(display-p3 0.024 0.565 0.129 / 0.036);
      --green-a3: color(display-p3 0.012 0.596 0.145 / 0.087);
      --green-a4: color(display-p3 0.008 0.588 0.145 / 0.142);
      --green-a5: color(display-p3 0.004 0.541 0.157 / 0.204);
      --green-a6: color(display-p3 0.004 0.518 0.157 / 0.283);
      --green-a7: color(display-p3 0.004 0.486 0.165 / 0.389);
      --green-a8: color(display-p3 0 0.478 0.2 / 0.55);
      --green-a9: color(display-p3 0 0.455 0.165 / 0.667);
      --green-a10: color(display-p3 0 0.416 0.153 / 0.691);
      --green-a11: color(display-p3 0.19 0.5 0.32);
      --green-a12: color(display-p3 0.132 0.228 0.18);
    }
  }
}