.dark, .dark-theme {
  --sand-1: #111110;
  --sand-2: #191918;
  --sand-3: #222221;
  --sand-4: #2a2a28;
  --sand-5: #31312e;
  --sand-6: #3b3a37;
  --sand-7: #494844;
  --sand-8: #62605b;
  --sand-9: #6f6d66;
  --sand-10: #7c7b74;
  --sand-11: #b5b3ad;
  --sand-12: #eeeeec;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --sand-1: color(display-p3 0.067 0.067 0.063);
      --sand-2: color(display-p3 0.098 0.098 0.094);
      --sand-3: color(display-p3 0.135 0.135 0.129);
      --sand-4: color(display-p3 0.164 0.163 0.156);
      --sand-5: color(display-p3 0.193 0.192 0.183);
      --sand-6: color(display-p3 0.23 0.229 0.217);
      --sand-7: color(display-p3 0.285 0.282 0.267);
      --sand-8: color(display-p3 0.384 0.378 0.357);
      --sand-9: color(display-p3 0.434 0.428 0.403);
      --sand-10: color(display-p3 0.487 0.481 0.456);
      --sand-11: color(display-p3 0.707 0.703 0.68);
      --sand-12: color(display-p3 0.933 0.933 0.926);
    }
  }
}