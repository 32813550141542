:root, .light, .light-theme {
  --sand-1: #fdfdfc;
  --sand-2: #f9f9f8;
  --sand-3: #f1f0ef;
  --sand-4: #e9e8e6;
  --sand-5: #e2e1de;
  --sand-6: #dad9d6;
  --sand-7: #cfceca;
  --sand-8: #bcbbb5;
  --sand-9: #8d8d86;
  --sand-10: #82827c;
  --sand-11: #63635e;
  --sand-12: #21201c;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --sand-1: #fdfdfc;
      --sand-2: #f9f9f8;
      --sand-3: #f1f0ef;
      --sand-4: #e9e9e6;
      --sand-5: #e2e1de;
      --sand-6: #dad9d6;
      --sand-7: #cfcfca;
      --sand-8: #bcbbb5;
      --sand-9: #8d8d86;
      --sand-10: #82827c;
      --sand-11: #63635e;
      --sand-12: #21201c;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --sand-1: color(display-p3 .992 .992 .989);
        --sand-2: color(display-p3 .977 .977 .973);
        --sand-3: color(display-p3 .943 .942 .936);
        --sand-4: color(display-p3 .913 .912 .903);
        --sand-5: color(display-p3 .885 .883 .873);
        --sand-6: color(display-p3 .854 .852 .839);
        --sand-7: color(display-p3 .813 .81 .794);
        --sand-8: color(display-p3 .738 .734 .713);
        --sand-9: color(display-p3 .553 .553 .528);
        --sand-10: color(display-p3 .511 .511 .488);
        --sand-11: color(display-p3 .388 .388 .37);
        --sand-12: color(display-p3 .129 .126 .111);
      }
    }
  }
}

:root, .light, .light-theme {
  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --amber-1: #fefdfb;
      --amber-2: #fefbe9;
      --amber-3: #fff7c2;
      --amber-4: #ffee9c;
      --amber-5: #fbe577;
      --amber-6: #f4d673;
      --amber-7: #e9c163;
      --amber-8: #e2a336;
      --amber-9: #ffc442;
      --amber-10: #ffba18;
      --amber-11: #a76600;
      --amber-12: #4f3422;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --amber-1: color(display-p3 .995 .992 .985);
        --amber-2: color(display-p3 .994 .986 .921);
        --amber-3: color(display-p3 .994 .969 .782);
        --amber-4: color(display-p3 .989 .937 .65);
        --amber-5: color(display-p3 .97 .902 .527);
        --amber-6: color(display-p3 .936 .844 .506);
        --amber-7: color(display-p3 .89 .762 .443);
        --amber-8: color(display-p3 .85 .65 .3);
        --amber-9: color(display-p3 1 .77 .26);
        --amber-10: color(display-p3 .959 .741 .274);
        --amber-11: color(display-p3 .64 .4 0);
        --amber-12: color(display-p3 .294 .208 .145);
      }
    }
  }
}

:root, .light, .light-theme {
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --violet-1: #fdfcfe;
      --violet-2: #faf8ff;
      --violet-3: #f4f0fe;
      --violet-4: #ebe5ff;
      --violet-5: #e1d9ff;
      --violet-6: #d4cafe;
      --violet-7: #c2b5f5;
      --violet-8: #aa99ec;
      --violet-9: #6e56cf;
      --violet-10: #654dc4;
      --violet-11: #6550b9;
      --violet-12: #2f265f;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --violet-1: color(display-p3 .991 .988 .995);
        --violet-2: color(display-p3 .978 .974 .998);
        --violet-3: color(display-p3 .953 .943 .993);
        --violet-4: color(display-p3 .916 .897 1);
        --violet-5: color(display-p3 .876 .851 1);
        --violet-6: color(display-p3 .825 .793 .981);
        --violet-7: color(display-p3 .752 .712 .943);
        --violet-8: color(display-p3 .654 .602 .902);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .381 .306 .741);
        --violet-11: color(display-p3 .383 .317 .702);
        --violet-12: color(display-p3 .179 .15 .359);
      }
    }
  }
}

:root, .light, .light-theme {
  --jade-1: #fbfefd;
  --jade-2: #f4fbf7;
  --jade-3: #e6f7ed;
  --jade-4: #d6f1e3;
  --jade-5: #c3e9d7;
  --jade-6: #acdec8;
  --jade-7: #8bceb6;
  --jade-8: #56ba9f;
  --jade-9: #29a383;
  --jade-10: #26997b;
  --jade-11: #208368;
  --jade-12: #1d3b31;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --jade-1: #fbfefd;
      --jade-2: #f4fbf7;
      --jade-3: #e5f7ed;
      --jade-4: #d6f1e3;
      --jade-5: #c3e9d7;
      --jade-6: #acdec9;
      --jade-7: #8bceb6;
      --jade-8: #55ba9f;
      --jade-9: #29a383;
      --jade-10: #26997b;
      --jade-11: #007f5e;
      --jade-12: #1d3b31;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --jade-1: color(display-p3 .986 .996 .992);
        --jade-2: color(display-p3 .962 .983 .969);
        --jade-3: color(display-p3 .912 .965 .932);
        --jade-4: color(display-p3 .858 .941 .893);
        --jade-5: color(display-p3 .795 .909 .847);
        --jade-6: color(display-p3 .715 .864 .791);
        --jade-7: color(display-p3 .603 .802 .718);
        --jade-8: color(display-p3 .44 .72 .629);
        --jade-9: color(display-p3 .319 .63 .521);
        --jade-10: color(display-p3 .299 .592 .488);
        --jade-11: color(display-p3 .15 .5 .37);
        --jade-12: color(display-p3 .142 .229 .194);
      }
    }
  }
}

:root, .light, .light-theme {
  --jade-a1: #00c08004;
  --jade-a2: #00a3460b;
  --jade-a3: #00ae4819;
  --jade-a4: #00a85129;
  --jade-a5: #00a2553c;
  --jade-a6: #009a5753;
  --jade-a7: #00945f74;
  --jade-a8: #00976ea9;
  --jade-a9: #00916bd6;
  --jade-a10: #008764d9;
  --jade-a11: #007152df;
  --jade-a12: #002217e2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --jade-a1: #00bd8704;
      --jade-a2: #0098440a;
      --jade-a3: #00944616;
      --jade-a4: #00924a24;
      --jade-a5: #008b4834;
      --jade-a6: #00834c49;
      --jade-a7: #007e4e65;
      --jade-a8: #007e598f;
      --jade-a9: #00724fae;
      --jade-a10: #006848b3;
      --jade-a11: #007f5e;
      --jade-a12: #1d3b31;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --jade-a1: color(display-p3 .024 .757 .514 / .016);
        --jade-a2: color(display-p3 .024 .612 .22 / .04);
        --jade-a3: color(display-p3 .012 .596 .235 / .087);
        --jade-a4: color(display-p3 .008 .588 .255 / .142);
        --jade-a5: color(display-p3 .004 .561 .251 / .204);
        --jade-a6: color(display-p3 .004 .525 .278 / .287);
        --jade-a7: color(display-p3 .004 .506 .29 / .397);
        --jade-a8: color(display-p3 0 .506 .337 / .561);
        --jade-a9: color(display-p3 0 .459 .298 / .683);
        --jade-a10: color(display-p3 0 .42 .271 / .702);
        --jade-a11: color(display-p3 .15 .5 .37);
        --jade-a12: color(display-p3 .142 .229 .194);
      }
    }
  }
}

:root, .light, .light-theme {
  --green-a1: #00c04004;
  --green-a2: #00a32f0b;
  --green-a3: #00a43319;
  --green-a4: #00a83829;
  --green-a5: #019c393b;
  --green-a6: #00963c52;
  --green-a7: #00914071;
  --green-a8: #00924ba4;
  --green-a9: #008f4acf;
  --green-a10: #008647d4;
  --green-a11: #00713fde;
  --green-a12: #002616e6;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --green-a1: #00bc5304;
      --green-a2: #008c3109;
      --green-a3: #00943516;
      --green-a4: #00923524;
      --green-a5: #00863434;
      --green-a6: #00813348;
      --green-a7: #00793463;
      --green-a8: #00773a8c;
      --green-a9: #007132aa;
      --green-a10: #00672eb0;
      --green-a11: #00804f;
      --green-a12: #193b2d;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --green-a1: color(display-p3 .024 .757 .267 / .016);
        --green-a2: color(display-p3 .024 .565 .129 / .036);
        --green-a3: color(display-p3 .012 .596 .145 / .087);
        --green-a4: color(display-p3 .008 .588 .145 / .142);
        --green-a5: color(display-p3 .004 .541 .157 / .204);
        --green-a6: color(display-p3 .004 .518 .157 / .283);
        --green-a7: color(display-p3 .004 .486 .165 / .389);
        --green-a8: color(display-p3 0 .478 .2 / .55);
        --green-a9: color(display-p3 0 .455 .165 / .667);
        --green-a10: color(display-p3 0 .416 .153 / .691);
        --green-a11: color(display-p3 .19 .5 .32);
        --green-a12: color(display-p3 .132 .228 .18);
      }
    }
  }
}

:root, .light, .light-theme {
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: #fbfdff;
      --blue-2: #f4faff;
      --blue-3: #e6f4fe;
      --blue-4: #d6eeff;
      --blue-5: #c4e5ff;
      --blue-6: #acd8fc;
      --blue-7: #8ec8f6;
      --blue-8: #5eb1ef;
      --blue-9: #0190ff;
      --blue-10: #0687f0;
      --blue-11: #0073d7;
      --blue-12: #113264;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --blue-1: color(display-p3 .986 .992 .999);
        --blue-2: color(display-p3 .96 .979 .998);
        --blue-3: color(display-p3 .912 .956 .991);
        --blue-4: color(display-p3 .853 .932 1);
        --blue-5: color(display-p3 .788 .894 .998);
        --blue-6: color(display-p3 .709 .843 .976);
        --blue-7: color(display-p3 .606 .777 .947);
        --blue-8: color(display-p3 .451 .688 .917);
        --blue-9: color(display-p3 .247 .556 .969);
        --blue-10: color(display-p3 .234 .523 .912);
        --blue-11: color(display-p3 .15 .44 .84);
        --blue-12: color(display-p3 .102 .193 .379);
      }
    }
  }
}

:root, .light, .light-theme {
  --blue-a1: #0080ff04;
  --blue-a2: #008cff0b;
  --blue-a3: #008ff519;
  --blue-a4: #009eff2a;
  --blue-a5: #0093ff3d;
  --blue-a6: #0088f653;
  --blue-a7: #0083eb71;
  --blue-a8: #0084e6a1;
  --blue-a9: #0090ff;
  --blue-a10: #0086f0fa;
  --blue-a11: #006dcbf2;
  --blue-a12: #002359ee;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-a1: #0088f804;
      --blue-a2: #0087e10a;
      --blue-a3: #0085e316;
      --blue-a4: #008ff825;
      --blue-a5: #0085f436;
      --blue-a6: #007be54a;
      --blue-a7: #0072d664;
      --blue-a8: #0071d38c;
      --blue-a9: #006fedc0;
      --blue-a10: #0066dbc3;
      --blue-a11: #0073d7;
      --blue-a12: #113264;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --blue-a1: color(display-p3 .024 .514 1 / .016);
        --blue-a2: color(display-p3 .024 .514 .906 / .04);
        --blue-a3: color(display-p3 .012 .506 .914 / .087);
        --blue-a4: color(display-p3 .008 .545 1 / .146);
        --blue-a5: color(display-p3 .004 .502 .984 / .212);
        --blue-a6: color(display-p3 .004 .463 .922 / .291);
        --blue-a7: color(display-p3 .004 .431 .863 / .393);
        --blue-a8: color(display-p3 0 .427 .851 / .55);
        --blue-a9: color(display-p3 0 .412 .961 / .753);
        --blue-a10: color(display-p3 0 .376 .886 / .765);
        --blue-a11: color(display-p3 .15 .44 .84);
        --blue-a12: color(display-p3 .102 .193 .379);
      }
    }
  }
}

.dark, .dark-theme {
  --sand-1: #111110;
  --sand-2: #191918;
  --sand-3: #222221;
  --sand-4: #2a2a28;
  --sand-5: #31312e;
  --sand-6: #3b3a37;
  --sand-7: #494844;
  --sand-8: #62605b;
  --sand-9: #6f6d66;
  --sand-10: #7c7b74;
  --sand-11: #b5b3ad;
  --sand-12: #eeeeec;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --sand-1: #111110;
      --sand-2: #191918;
      --sand-3: #222221;
      --sand-4: #2a2a28;
      --sand-5: #31312e;
      --sand-6: #3b3a37;
      --sand-7: #494844;
      --sand-8: #62605a;
      --sand-9: #6f6d66;
      --sand-10: #7d7b74;
      --sand-11: #b5b3ad;
      --sand-12: #eeeeec;
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --sand-1: color(display-p3 .067 .067 .063);
        --sand-2: color(display-p3 .098 .098 .094);
        --sand-3: color(display-p3 .135 .135 .129);
        --sand-4: color(display-p3 .164 .163 .156);
        --sand-5: color(display-p3 .193 .192 .183);
        --sand-6: color(display-p3 .23 .229 .217);
        --sand-7: color(display-p3 .285 .282 .267);
        --sand-8: color(display-p3 .384 .378 .357);
        --sand-9: color(display-p3 .434 .428 .403);
        --sand-10: color(display-p3 .487 .481 .456);
        --sand-11: color(display-p3 .707 .703 .68);
        --sand-12: color(display-p3 .933 .933 .926);
      }
    }
  }
}

*, :before, :after {
  box-sizing: border-box;
}

button {
  background: none;
  border: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

::selection {
  color: #fff;
  background-color: #aa99eccc;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  --og-gradient: radial-gradient(at 92% 74%, #f292b2b3 0px, transparent 50%), radial-gradient(at 4% 45%, #ffe77ab3 0px, transparent 40%), radial-gradient(circle at bottom, var(--violet-8) 15%, var(--amber-2) 70%, transparent 100%);
  --hero-gradient: radial-gradient(at 92% 47%, #f292b2b3 0px, transparent 50%), radial-gradient(at 4% 17%, #ffe77ab3 0px, transparent 40%), radial-gradient(circle at top, var(--violet-8) 15%, var(--amber-2) 70%, transparent 100%);
  --footer-gradient: radial-gradient(at 92% 96%, #ffe77a80 0px, transparent 40%), radial-gradient(at 4% 70%, #f292b2b3 0px, transparent 60%), radial-gradient(circle at bottom, var(--violet-8) 15%, transparent 60%);
  --blue-section-gradient: radial-gradient(circle at top, var(--blue-a6) 10%, transparent 40%);
  --yellow-section-gradient: radial-gradient(circle, #ffe77a4d, white);
  --red-section-gradient: linear-gradient(to bottom, #f292b2b3, white);
  --combined-section-gradient: radial-gradient(circle at 50% 20%, #ffe77a33, transparent 40%), radial-gradient(circle at 50% 50%, #a27ef133, transparent 40%), radial-gradient(circle at 50% 80%, #f292b233, transparent 40%);
  --call-out-section-gradient: radial-gradient(300px circle at 92% 25%, #f292b2b3 0px, transparent 100%), radial-gradient(400px 300px at 8% 10%, #ffe77ab3 0px, transparent 100%), radial-gradient(650px 400px at 50% 25%, var(--violet-7) 15%, var(--jade-3) 65%, transparent 100%);
  --noise-svg: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20250%20250%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cfilter%20id%3D%22noiseFilter%22%3E%0A%20%20%20%20%3C%21--%20%3CfeTurbulence%20type%3D%27fractalNoise%27%20baseFrequency%3D%27.537%27%2F%3E%0A%20%20%20%20%3CfeColorMatrix%20type%3D%27saturate%27%20values%3D%270%27%2F%3E%0A%20%20%20%20%3CfeBlend%20in%3D%27SourceGraphic%27%20mode%3D%27multiply%27%2F%3E%20--%3E%0A%20%20%20%20%3CfeTurbulence%20baseFrequency%3D%220.65%22%20numOctaves%3D%221%22%20stitchTiles%3D%22stitch%22%3E%3C%2FfeTurbulence%3E%0A%20%20%3C%2Ffilter%3E%0A%0A%20%20%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20filter%3D%22url%28%27%23noiseFilter%27%29%22%3E%3C%2Frect%3E%0A%3C%2Fsvg%3E%0A");
  --grid-svg: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asvgjs%3D%22http%3A%2F%2Fsvgjs.dev%2Fsvgjs%22%20viewBox%3D%220%200%20800%20800%22%20width%3D%22250%22%20height%3D%22250%22%3E%0A%20%20%3Cg%20stroke-width%3D%223.5%22%20stroke%3D%22%23ffffff09%22%20fill%3D%22none%22%20transform%3D%22rotate%2814deg%29%22%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI (Custom)", Roboto, Helvetica Neue, "Open Sans (Custom)", system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.og-gradient {
  background: var(--og-gradient);
}

.og-gradient:before {
  content: "";
  background-image: var(--noise-svg);
  z-index: 1;
  pointer-events: none;
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  mask-image: radial-gradient(circle at bottom, #fff2 0%, #fff3 30%, #0000 80%);
  mask-size: cover;
}

.hero-gradient {
  background: var(--hero-gradient);
}

.hero-gradient:before {
  content: "";
  background-color: var(--amber-5);
  background-image: var(--noise-svg);
  z-index: 1;
  pointer-events: none;
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  mask-image: radial-gradient(circle at top, #fff2 0%, #fff3 30%, #0000 80%);
  mask-size: cover;
}

.footer-gradient {
  background: var(--footer-gradient);
  background-repeat: no-repeat;
}

.footer-gradient:before {
  content: "";
  opacity: .3;
  background-image: var(--noise-svg);
  mask-image: var(--footer-gradient);
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  mask-size: cover;
}

.call-out-section-gradient {
  background: var(--call-out-section-gradient);
}

.call-out-section-gradient:before {
  content: "";
  background-color: var(--amber-5);
  background-image: var(--noise-svg);
  opacity: .2;
  mask-image: var(--call-out-section-gradient);
  pointer-events: none;
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  mask-size: cover;
}

.grid-background:before {
  content: "";
  background-image: var(--grid-svg);
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  transform: skewY(2.7deg)rotate(-2.7deg);
}

.grid-background-backward:before {
  content: "";
  background-image: var(--grid-svg);
  background-repeat: repeat;
  background-size: 250px 250px;
  position: absolute;
  inset: 0;
  transform: skewY(-2.7deg)rotate(2.7deg);
}
/*# sourceMappingURL=index.fce4e172.css.map */
