:root, .light, .light-theme {
  --jade-1: #fbfefd;
  --jade-2: #f4fbf7;
  --jade-3: #e6f7ed;
  --jade-4: #d6f1e3;
  --jade-5: #c3e9d7;
  --jade-6: #acdec8;
  --jade-7: #8bceb6;
  --jade-8: #56ba9f;
  --jade-9: #29a383;
  --jade-10: #26997b;
  --jade-11: #208368;
  --jade-12: #1d3b31;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --jade-1: color(display-p3 0.986 0.996 0.992);
      --jade-2: color(display-p3 0.962 0.983 0.969);
      --jade-3: color(display-p3 0.912 0.965 0.932);
      --jade-4: color(display-p3 0.858 0.941 0.893);
      --jade-5: color(display-p3 0.795 0.909 0.847);
      --jade-6: color(display-p3 0.715 0.864 0.791);
      --jade-7: color(display-p3 0.603 0.802 0.718);
      --jade-8: color(display-p3 0.44 0.72 0.629);
      --jade-9: color(display-p3 0.319 0.63 0.521);
      --jade-10: color(display-p3 0.299 0.592 0.488);
      --jade-11: color(display-p3 0.15 0.5 0.37);
      --jade-12: color(display-p3 0.142 0.229 0.194);
    }
  }
}