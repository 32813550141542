@import "npm:@radix-ui/colors/sand.css";
@import "npm:@radix-ui/colors/amber.css";
@import "npm:@radix-ui/colors/violet.css";
@import "npm:@radix-ui/colors/jade.css";
@import "npm:@radix-ui/colors/jade-alpha.css";
@import "npm:@radix-ui/colors/green-alpha.css";
@import "npm:@radix-ui/colors/blue.css";
@import "npm:@radix-ui/colors/blue-alpha.css";
@import "npm:@radix-ui/colors/sand-dark.css";

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Reset */
button {
	background: none;
	border: none;
	padding: 0;
}
a {
	text-decoration: none;
	color: inherit;
}

/* Text highlight color */
::selection {
	background-color: #aa99eccc;
	color: white;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI (Custom)", Roboto,
		"Helvetica Neue", "Open Sans (Custom)", system-ui, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	margin: 0;

	--og-gradient: radial-gradient(
			at 92% 74%,
			hsla(340, 78%, 76%, 0.7) 0px,
			transparent 50%
		),
		radial-gradient(at 4% 45%, hsla(49, 100%, 74%, 0.7) 0px, transparent 40%),
		radial-gradient(
			circle at bottom,
			var(--violet-8) 15%,
			var(--amber-2) 70%,
			transparent 100%
		);
	--hero-gradient: radial-gradient(
			at 92% 47%,
			hsla(340, 78%, 76%, 0.7) 0px,
			transparent 50%
		),
		radial-gradient(at 4% 17%, hsla(49, 100%, 74%, 0.7) 0px, transparent 40%),
		radial-gradient(
			circle at top,
			var(--violet-8) 15%,
			var(--amber-2) 70%,
			transparent 100%
		);

	--footer-gradient: radial-gradient(
			at 92% 96%,
			hsla(49, 100%, 74%, 0.5) 0px,
			transparent 40%
		),
		radial-gradient(at 4% 70%, hsla(340, 78%, 76%, 0.7) 0px, transparent 60%),
		radial-gradient(circle at bottom, var(--violet-8) 15%, transparent 60%);

	--blue-section-gradient: radial-gradient(
		circle at top,
		var(--blue-a6) 10%,
		transparent 40%
	);

	/* --yellow-section-gradient: linear-gradient(to bottom, hsla(49, 100%, 74%, 0.5), white); */
	/* --yellow-section-gradient: linear-gradient(to bottom, hsla(49, 100%, 74%, 0.3), white); */
	--yellow-section-gradient: radial-gradient(
		circle,
		hsla(49, 100%, 74%, 0.3),
		white
	);
	--red-section-gradient: linear-gradient(
		to bottom,
		hsla(340, 78%, 76%, 0.7),
		white
	);

	/* --combined-section-gradient: linear-gradient(to bottom, hsla(49, 100%, 74%, 0.3), hsla(340, 78%, 76%, 0.3)); */
	--combined-section-gradient: radial-gradient(
			circle at 50% 20%,
			hsla(49, 100%, 74%, 0.2),
			transparent 40%
		),
		/* Yellow circle near the top */
			radial-gradient(
				circle at 50% 50%,
				hsla(259, 81%, 72%, 0.2),
				transparent 40%
			),
		/* Violet circle near the bottom */
			radial-gradient(
				circle at 50% 80%,
				hsla(340, 78%, 76%, 0.2),
				transparent 40%
			); /* Red circle in the middle */

	/* radial-gradient(
			at 92% 96%,
			hsla(49, 100%, 74%, 0.5) 0px,
			transparent 40%
		),
		radial-gradient(at 4% 70%, hsla(340, 78%, 76%, 0.7) 0px, transparent 60%), */
	/* radial-gradient(circle at bottom, var(--violet-8) 15%, transparent 60%); */

	--call-out-section-gradient: radial-gradient(
			300px circle at 92% 25%,
			hsla(340, 78%, 76%, 0.7) 0px,
			transparent 100%
		),
		radial-gradient(
			400px 300px at 8% 10%,
			hsla(49, 100%, 74%, 0.7) 0px,
			transparent 100%
		),
		radial-gradient(
			650px 400px at 50% 25%,
			var(--violet-7) 15%,
			var(--jade-3) 65%,
			transparent 100%
		);

	--noise-svg: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20250%20250%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cfilter%20id%3D%22noiseFilter%22%3E%0A%20%20%20%20%3C%21--%20%3CfeTurbulence%20type%3D%27fractalNoise%27%20baseFrequency%3D%27.537%27%2F%3E%0A%20%20%20%20%3CfeColorMatrix%20type%3D%27saturate%27%20values%3D%270%27%2F%3E%0A%20%20%20%20%3CfeBlend%20in%3D%27SourceGraphic%27%20mode%3D%27multiply%27%2F%3E%20--%3E%0A%20%20%20%20%3CfeTurbulence%20baseFrequency%3D%220.65%22%20numOctaves%3D%221%22%20stitchTiles%3D%22stitch%22%3E%3C%2FfeTurbulence%3E%0A%20%20%3C%2Ffilter%3E%0A%0A%20%20%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20filter%3D%22url%28%27%23noiseFilter%27%29%22%3E%3C%2Frect%3E%0A%3C%2Fsvg%3E%0A");
	--grid-svg: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asvgjs%3D%22http%3A%2F%2Fsvgjs.dev%2Fsvgjs%22%20viewBox%3D%220%200%20800%20800%22%20width%3D%22250%22%20height%3D%22250%22%3E%0A%20%20%3Cg%20stroke-width%3D%223.5%22%20stroke%3D%22%23ffffff09%22%20fill%3D%22none%22%20transform%3D%22rotate%2814deg%29%22%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%220%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%22400%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%220%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22400%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%20%20%3Crect%20width%3D%22400%22%20height%3D%22400%22%20x%3D%22800%22%20y%3D%22800%22%3E%3C%2Frect%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
}

.og-gradient {
	background: var(--og-gradient);
}

.og-gradient::before {
	content: "";
	position: absolute;
	inset: 0;

	/* background-color: var(--amber-1); */
	background-image: var(--noise-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
	/* Use a mask to fade out the noise with the gradient */
	mask-image: radial-gradient(
		circle at bottom,
		#ffffff22 0%,
		#ffffff33 30%,
		transparent 80%
	);
	mask-size: cover;

	z-index: 1;
	pointer-events: none;
}

.hero-gradient {
	background: var(--hero-gradient);
}

.hero-gradient::before {
	content: "";
	position: absolute;
	inset: 0;

	background-color: var(--amber-5);
	background-image: var(--noise-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
	/* Use a mask to fade out the noise with the gradient */
	mask-image: radial-gradient(
		circle at top,
		#ffffff22 0%,
		#ffffff33 30%,
		transparent 80%
	);
	mask-size: cover;

	z-index: 1;
	pointer-events: none;
}

.footer-gradient {
	background: var(--footer-gradient);
	background-repeat: no-repeat;
}

.footer-gradient::before {
	content: "";
	position: absolute;
	inset: 0;

	opacity: 0.3;
	background-image: var(--noise-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
	/* Use a mask to fade out the noise with the gradient */
	mask-image: var(--footer-gradient);
	mask-size: cover;
}

.call-out-section-gradient {
	background: var(--call-out-section-gradient);
}

.call-out-section-gradient::before {
	content: "";
	position: absolute;
	inset: 0;

	background-color: var(--amber-5);
	background-image: var(--noise-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
	/* Use a mask to fade out the noise with the gradient */
	opacity: 0.2;
	mask-image: var(--call-out-section-gradient);

	mask-size: cover;

	pointer-events: none;
}

.grid-background::before {
	content: "";
	position: absolute;
	inset: 0;
	transform: skewY(2.7deg) rotate(-2.7deg);

	background-image: var(--grid-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
}

.grid-background-backward::before {
	content: "";
	position: absolute;
	inset: 0;
	transform: skewY(-2.7deg) rotate(2.7deg);

	background-image: var(--grid-svg);
	background-size: 250px 250px;
	background-repeat: repeat;
}
